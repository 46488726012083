import React from "react";
import { graphql } from "gatsby";

import { mainNavigation, homesNavigation } from "src/data";
import Header from "components/Header";
import Banner from "components/Banner";
import Layout from "components/Layout";
import Footer from "components/Footer";
import ImageRow from "components/ImageRow";
import CookieBar from "components/CookieBar";

import "styles/styles.scss";

const IndexPage = ({ data }) => {
  const { logo, content } = data;
  return (
    <>
      <Header
        main={mainNavigation}
        homes={homesNavigation}
        logo={logo.childImageSharp.fluid}
        logoAlt="Moodu moduliniai nameliai logotipas"
      />
      {content.edges.map((item, idx) => (
        <React.Fragment key={idx}>
          <Banner
            heading={item.node.mainHeading}
            isInnerPage
            isCentered
            imageData={item.node.backgroundImage.fluid}
          />
          <Layout>
            <ImageRow
              subheading={item.node.heading1}
              text={item.node.textField1.json}
              img={item.node.photo1.fluid}
              imgAlt={item.node.photo1.title}
              isReversed
            />
            <ImageRow
              subheading={item.node.heading2}
              text={item.node.textField2.json}
              img={item.node.photo2.fluid}
              imgAlt={item.node.photo2.title}
            />
            <ImageRow
              subheading={item.node.heading3}
              text={item.node.textField3.json}
              img={item.node.photo3.fluid}
              imgAlt={item.node.photo3.title}
              isReversed
            />
            <ImageRow
              subheading={item.node.heading4}
              text={item.node.textField4.json}
              img={item.node.photo4.fluid}
              imgAlt={item.node.photo4.title}
            />
            <ImageRow
              subheading={item.node.heading5}
              text={item.node.textField5.json}
              img={item.node.photo5.fluid}
              imgAlt={item.node.photo5.title}
              isReversed
              hasButton
            />
          </Layout>
          <Footer logo={logo.childImageSharp.fluid} />
          <CookieBar />
        </React.Fragment>
      ))}
    </>
  );
};

export const query = graphql`
  query AboutUs {
    logo: file(relativePath: { eq: "logo-top.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    content: allContentfulAboutUs {
      edges {
        node {
          mainHeading
          heading1
          heading2
          heading3
          heading4
          heading5
          textField1 {
            json
          }
          textField2 {
            json
          }
          textField3 {
            json
          }
          textField4 {
            json
          }
          textField5 {
            json
          }
          backgroundImage {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          photo1 {
            title
            fluid(maxWidth: 840) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          photo2 {
            title
            fluid(maxWidth: 840) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          photo3 {
            title
            fluid(maxWidth: 840) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          photo4 {
            title
            fluid(maxWidth: 840) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          photo5 {
            title
            fluid(maxWidth: 840) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
